export const PROJECT_NAME = 'Oneof';
export const BASE_URL = 'https://api.1of.la';
export const WEBSITE_URL = 'https://1of.la';
export const api_url = BASE_URL + '/api/v1/';
export const api_url_admin = BASE_URL + '/api/v1/dashboard/admin/';
export const api_url_admin_dashboard = BASE_URL + '/api/v1/dashboard/';
export const IMG_URL = '';
export const MAP_API_KEY = 'AIzaSyCHKiOVXbE1vu-H55L42KHzfHYNqXPLWMo';
export const export_url = BASE_URL + '/storage/';
export const VAPID_KEY =
  'BFlL8CjM0CM8pzCeqj9ed4Xyv02MHx64ZVQgr-ob3XGRZEnKNP1dFNRlfceS1uqFUpfti8WpdUSBeKanpMrf5lw';

export const RECAPTCHASITEKEY = '6LdRLg8oAAAAAANBPQicDRpbclUy0_EdSFVkCMe1';
export const API_KEY = 'AIzaSyCmgbnqBCpPED9-KCrmV2va2nlAP0R1XlA';
export const AUTH_DOMAIN = 'oneof-a0541.firebaseapp.com';
export const PROJECT_ID = 'oneof-a0541';
export const STORAGE_BUCKET = 'oneof-a0541.appspot.com';
export const MESSAGING_SENDER_ID = '314461219505';
export const APP_ID = '1:314461219505:web:83aca3c8f7e1145d471090';
export const MEASUREMENT_ID = 'G-CWW8RV76Z6';
export const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/svg',
];

export const DEMO_SELLER = 210; // seller_id
export const DEMO_SHOP = 599; // seller_id
export const DEMO_DELIVERYMAN = 375; // deliveryman_id
export const DEMO_MANEGER = 114; // deliveryman_id
export const DEMO_MODERATOR = 297; // deliveryman_id
export const DEMO_ADMIN = 107; // deliveryman_id
